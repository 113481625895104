<template>
    <div>
        <div style="margin-top: 20px">
            <div style="height:520px;">
                <div id="all" class="all" style="height:100%">
                    <div class="posInput">
                        <el-select
                            style="width:100%"
                            v-model="mapAdress"
                            filterable
                            remote
                            placeholder="请输入关键词"
                            :remote-method="remoteMethod"
                            @change="onChangeSelectAddress"
                            :loading="loading">
                            <el-option
                                v-for="(item, index) in searchData"
                                :key="index"
                                :label="item.address"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div id="allmap" class="allmap"></div>
                    <div class="posSubmit">
                        <div style="display: flex;">
                            <el-form ref="form" label-width="85px" >
                                <div class="btn_box" >
                                    <el-form-item label="地址:" >
                                        <el-input style="width:200px" disabled class="form-control input-style" type="text" v-model="insureAdress"> </el-input>
                                    </el-form-item>
                                    <el-form-item label="坐标:" >
                                        <el-input style="width:200px"  disabled class="form-control input-style" type="text" v-model="insureAdress2"> </el-input>
                                    </el-form-item>
                                </div>
                            </el-form>
                        </div>

                        <div style="display: flex;">
                            <el-button type="primary" size="large" style="margin-left:10px;" @click="insureMapAdress">确定</el-button>
                            <el-button size="large" style="margin-left:10px;" @click="onClose">关闭</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import axios from 'axios'
export default {
    name: 'SelectAMapAddress',
    emits: ['confirmClick', 'closed'],
    data() {
        return {
            map: null,
            marker:null,
            thisPosition: {
                value:'',
                location: '',
                lng: '',
                lat: '',
                code: '',
            },
            searchData: [],
            mapAdress: '',
            insureAdress:'',
            insureAdress2:'',
            loading: false,
        }
    },
    mounted() {
        this.loadmap()
    },
    methods: {
        loadmap() {
            const that = this
            return new Promise((reslove, reject) => {
                AMapLoader.load({
                    key: "778748e94c45452d7df1a58ab2056af1", // 申请好的Web端开发者Key，首次调用 load 时必填  todo 暂时用的魔女科技
                    version: "2.0",
                    plugins: ['AMap.ToolBar', 'AMap.Scale'],
                    AMapUI: {
                        version: "1.1",
                        plugins: [],
                    },
                })
                    .then((AMap) => {
                        that.map = new AMap.Map("allmap", {
                            resizeEnable: true,
                            zoom: 4,
                            center: [116.397428, 39.90923],//中心点坐标
                        })
                        that.map.addControl(new AMap.Scale())
                        that.map.addControl(new AMap.ToolBar())
                        that.map.setZoom(6)
                        reslove()
                    })
                    .catch((e) => {
                        console.log(e, "高德地图加载失败")
                        reject(e)
                    })
            })
        },

        async remoteMethod(query) {
            if (query !== '') {
                this.loading = true;
                const response = await axios({
                    method: 'get',
                    url: `https://restapi.amap.com/v3/assistant/inputtips?key=9e1a283478207d44f4d8ef18e3986b1d&keywords=${query}`,
                    data: {}
                });
                const {data,status} = response;
                if(status === 200 && data.status === '1') {
                    let searchData = [];
                    for(let item of data.tips){
                        searchData.push({
                            id: item.id,
                            value: item.name,
                            address: item.district + item.name,
                            adcode: item.adcode,
                            location: item.location,
                        });
                    }
                    this.searchData = searchData;
                    this.loading = false;
                }else {
                    this.loading = false;
                }
            }
        },

        onChangeSelectAddress(e) {
            let idx =this.searchData.findIndex((item, index) => {
                return item.id === e;
            });
            if(idx > -1) {
                let data = this.searchData[idx];
                if(data.location) {
                    let location = data.location.split(',');
                    this.insureAdress = data.address;
                    this.insureAdress2 = '['+location[0]+','+location[1]+']'
                    this.thisPosition = {
                        value: data.value,
                        address: data.address,
                        location: data.location,
                        lng: location[0],
                        lat: location[1],
                        code: data.adcode,
                    }
                    if(this.marker){
                        this.map.remove(this.marker)
                        this.marker =null;
                    }
                    this.marker = new AMap.Marker({
                        position: [location[0], location[1]]//位置
                    })
                    this.map.add(this.marker);//添加到地图
                    // 传入经纬度，设置地图中心点
                    this.map.setCenter([location[0], location[1]]);
                    this.map.setZoom(15)
                }else {
                    this.$toast('未获取到经纬度');
                }
            }
        },

        insureMapAdress(){//确定修改地址
            this.$confirm('此操作为确认该地址, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {}
                data.longitute = this.thisPosition.lng
                data.latitude = this.thisPosition.lat
                data.address = this.insureAdress
                this.$emit('confirmClick', data);
            })
        },

        onClose() {
            this.$emit('closed');
        }
    },

}
</script>
<style>
.all{
    position: relative;
}
.allmap{
    width: 100%;  height: calc(100%  - 50px);
    font-family: "微软雅黑";
}
.posInput{
    position: absolute;
    z-index: 1;
    width: 80%;
    margin-top: 20px;
    margin-left: 10%;
}
.posSubmit{
    position: absolute;
    z-index: 1;
    width: 90%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.btn_box{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}
::v-deep .el-form-item{
    margin-bottom: 0 !important;
}
/*.rightPanel{*/
/*    z-index: 1000 !important;*/
/*}*/
.el-dialog__wrapper {
    z-index: 1002 !important;
}
.v-modal{
    z-index: 1001 !important;
}

</style>
